import { useMemo, useState } from 'react';

import { useDebounce } from 'react-use';

import logger from '@common/log';
import postalCodeRegex from '@common/validation/postalCodeRegex';
import {
  useAddressPublicGetAddressPublic,
  useAddressGetAddressPrivate,
  useAddressPublicGetAddressBusinessCheck,
} from '@dc/hooks';
import { Customers_Address_AddressModel } from '@monorepo-types/dc';

type useAddressLookupFormArguments = {
  postalCode?: string;
  houseNumber?: string;
  houseNumberSuffix?: string;
};

type AddressApiFetchParameters = {
  postalCode: string;
  houseNumber: string;
  houseNumberSuffix: string;
} | null;

const getAddressApiFetchParameters = (address?: Customers_Address_AddressModel): AddressApiFetchParameters =>
  address?.postalCode && address?.houseNumber
    ? {
        postalCode: address.postalCode,
        houseNumber: address.houseNumber,
        houseNumberSuffix: '',
      }
    : null;

export const emptySuffixString = '-';

const getHouseNumberSuffixOptions = (data?: Customers_Address_AddressModel) =>
  data?.houseNumberSuffixes?.map(value => ({
    label: value || emptySuffixString,
    value: value || emptySuffixString,
  })) || [];

/**
 * Hook to watch for form changes and outputs new parameters to be used by the address APIs;
 * the input is debounced to minimize load on backend services.
 */
export const useAddressFormWatcher = ({
  postalCode,
  houseNumber,
  houseNumberSuffix,
}: useAddressLookupFormArguments) => {
  const [parameters, setParameters] = useState<AddressApiFetchParameters>(null);
  const isValidInput = useMemo(
    () => !!postalCode && postalCodeRegex.test(postalCode) && !!houseNumber && +houseNumber > 0,
    [postalCode, houseNumber],
  );

  // Debounce the input of the user by 500 ms.
  useDebounce(
    () => {
      if (!isValidInput) return;

      setParameters(
        getAddressApiFetchParameters({
          postalCode,
          houseNumber,
        }),
      );
    },
    500,
    [postalCode, houseNumber, isValidInput],
  );

  return useMemo(
    () => ({
      parameters,
      houseNumberSuffix,
      isValidInput,
      emptySuffixString,
    }),
    [parameters, houseNumberSuffix, isValidInput],
  );
};

/**
 * Hook for calling the GetAddressPublic api
 */
export const useAddressPublicApi = (parameters: AddressApiFetchParameters) => {
  const parametersCopy: AddressApiFetchParameters = parameters
    ? {
        ...parameters,
        // API does not accept a space
        postalCode: parameters.postalCode.replace(' ', ''),
      }
    : null;

  const { data, isValidating, error } = useAddressPublicGetAddressPublic(parametersCopy, {
    onError: error => {
      if (error.status !== 404) logger.error('ofPfyU', 'Unexpected DC error for getAddressPublic', error);
    },
  });

  return useMemo(() => {
    const houseNumberSuffixOptions = getHouseNumberSuffixOptions(data);
    return {
      data,
      isValidating,
      error,
      houseNumberSuffixOptions,
    };
  }, [data, isValidating, error]);
};

/**
 * Hook for calling the GetAddressBusinessCheck api
 */
export const useAddressBusinessCheckApi = (
  parameters: AddressApiFetchParameters,
): { data: { isLargeBusiness: boolean }; isValidating: boolean; error: unknown } => {
  const parametersCopy: AddressApiFetchParameters = parameters
    ? {
        ...parameters,
        // API does not accept a space
        postalCode: parameters.postalCode.replace(' ', ''),
      }
    : null;

  const { data, isValidating, error } = useAddressPublicGetAddressBusinessCheck(parametersCopy, {
    onError: error => {
      if (error.status !== 404) logger.error('UFjYhn', 'Unexpected DC error for getAddressBusinessCheck', error);
    },
  });

  return useMemo(() => {
    return {
      data: data as { isLargeBusiness: boolean },
      isValidating,
      error,
    };
  }, [data, isValidating, error]);
};

/**
 * Hook for checking if the address registered as a large business
 */
export const useIsLargeBusinessCheck = (address: useAddressLookupFormArguments) => {
  const { parameters } = useAddressFormWatcher(address);
  const { data } = useAddressBusinessCheckApi(parameters);
  return data?.isLargeBusiness;
};

/**
 * Hook for calling the GetAddressPrivate
 */
export const useAddressPrivateApi = (parameters: AddressApiFetchParameters) => {
  const { data, isValidating, error } = useAddressGetAddressPrivate(parameters, {
    onError: error => {
      if (error.status !== 404) logger.error('tzvxE8', 'Unexpected DC error for getAddressPrivate', error);
    },
  });

  return useMemo(() => {
    const houseNumberSuffixOptions = getHouseNumberSuffixOptions(data);
    return {
      data,
      isValidating,
      error,
      houseNumberSuffixOptions,
    };
  }, [data, isValidating, error]);
};
